.bn-onboard-custom {
  z-index: 1000;
}
.bn-onboard-modal-content {
  background-color: #312750 !important;
}
.bn-onboard-icon-button:hover {
  background-color: #170f2b !important;
}
.bn-onboard-icon-button:focus {
  outline: thick double #8424e2 !important;
}
.bn-onboard-dark-mode-background-hover:hover {
  background-color: #170f2b !important;
}
.bn-onboard-prepare-button {
  border: 2px solid transparent !important;
}
.bn-onboard-prepare-button:hover {
  border-color: white !important;
}
.bn-onboard-prepare-button:focus {
  outline: thick double #8424e2 !important;
}
.bn-onboard-custom.bn-onboard-dark-mode-link {
  background-color: #8424e2 !important;
  border-color: #8424e2 !important;
  color: white !important;
}
.bn-onboard-selected-wallet {
  background-color: #242424 !important;
}
.bn-onboard-dark-mode-close-background {
  background-color: #242424 !important;
}

.bn-onboard-icon-button,
.bn-onboard-dark-mode-link {
  border-radius: 9px !important;
}
.bn-onboard-modal-content-header-icon {
  display: none !important;
}

.bn-onboard-modal-content-header-heading {
  margin: 0 !important;
}
.bn-onboard-select-info-container span {
  color: #fff !important;
  font-size: 0.8rem;
  font-weight: 500;
}
